<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="mb-8 size-full"
    :class="[
      ...marginClasses,
      {
        container: blok.in_container,
        '-px-4': !blok.in_container,
      },
    ]"
    :threshold="0.5"
    @enter="intersectPromotionView"
  >
    <div
      class="relative sm:flex"
      :class="{
        'h-[30rem] sm:h-screen': isFullHeight,
        'h-[25rem]': isSmallHeight,
        'h-[30rem]': isMediumHeight,
        'h-[30rem] md:h-[35rem]': isLargeHeight,
        'h-[36rem] md:h-[41rem]': isXtraLargeHeight,
        'sm:flex-col': showButtonBox,
      }"
    >
      <FimPicture
        v-if="image"
        class="z-0"
        :mobile="image.srcMobile"
        :desktop="image.srcDesktop"
        :focus-mobile="image.focusMobile"
        :focus-desktop="image.focusDesktop"
        provider="storyblok"
        layout="fill"
        :preload="preloadMedia"
        :object-position="image.objectPosition"
        :object-fit="image.objectFit || 'cover'"
        :ratio-mobile="image.srcMobile ? undefined : 1 / 1"
        :modifiers="{ smart: true }"
        sizes="(max-width: 1400px) 100vw, 1400px"
        :widths="{
          xs: 500,
          sm: 640,
          md: 960,
          xl: 1280,
          '2xl': 1400,
        }"
      />
      <div
        class="flex size-full px-4 py-6 md:px-6 lg:p-12"
        :class="{
          ...gradientPosition,
          'gradient-light': isDark && hasGradient,
          'gradient-dark': isLight && hasGradient,
        }"
      >
        <div
          class="z-10 flex"
          :class="[
            ...contentWidthClasses,
            {
              'mx-auto': blok.align === 'center',
              'ml-auto': blok.align === 'end',
              'mr-auto': blok.align === 'start',
            },
          ]"
        >
          <div
            class="flex flex-col"
            :class="{
              'text-white': isLight,
              'text-black': isDark,
              'text-right sm:text-left': blok.variant === 'end',
              'relative items-start justify-end pb-0 lg:pb-0 ': showStoreFinder,
              'justify-end sm:justify-center': !showStoreFinder,
            }"
          >
            <div>
              <p
                v-if="blok.overline"
                class="mb-2 max-w-2xs text-xl font-medium"
              >
                {{ blok.overline }}
              </p>
              <FimPicture
                v-if="logoImageSrc"
                class="cms-picture hidden sm:inline-block"
                height="auto"
                sizes="sm:160"
                provider="storyblok"
                :desktop="logoImageSrc"
                :alt="logoImageAlt"
              />
              <Headline
                v-if="headline"
                v-bind="{ ...headline }"
                class="mb-2 hyphens-auto break-words"
              >
                {{ headline.text }}
              </Headline>

              <div
                v-if="text && text.body"
                class="with-checkmarks mb-2 hidden text-xl sm:block"
              >
                <RichTextRenderer :document="text.body" />
              </div>
              <CmsButton
                v-if="link"
                :id="'hero-' + blok._uid"
                :blok="link"
                class="mt-2"
                is-link
                :target="blok.cta_target_blank ? '_blank' : '_self'"
                :type="buttonType"
                @mousedown="trackClick"
              >
                {{ link.cta_label }}
                <template #iconAfter>
                  <IconFielmannArrowRight class="size-4" />
                </template>
              </CmsButton>
            </div>
            <div
              v-if="showStoreFinder && !showButtonBox"
              class="hidden w-full max-w-lg translate-y-6 justify-center bg-neutral-15 text-black lg:flex lg:px-5 lg:py-6"
            >
              <div class="mr-2 mt-1 w-10 shrink-0">
                <IconFielmannMeeting class="size-10" />
              </div>
              <div class="flex lg:flex-col">
                <Headline tag="p" look="h1" weight="medium">{{
                  $t('hero.store_finder.heading')
                }}</Headline>
                <span class="mb-1">{{ $t('hero.store_finder.copy') }}</span>
                {{'<HeroStoreFinderSearch v-if="showStoreFinder" />'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonBox
        v-for="(boxBlok, i) in blok.buttonBox"
        :key="i"
        id-prefix="desktop-"
        class="z-10 mx-auto mb-8 hidden w-[95%] md:flex"
        :blok="boxBlok"
      />
    </div>
    <div
      v-if="text"
      class="with-checkmarks px-4 py-2.5 sm:hidden md:p-6"
      :class="{
        'text-white': accentLuminance < 0.5,
        'text-black': accentLuminance >= 0.5,
      }"
      :style="{ backgroundColor: accentColor }"
    >
      <RichTextRenderer :document="text.body" />
    </div>
    <div
      v-if="showStoreFinder && !showButtonBox"
      class="flex w-full justify-center bg-neutral-15 p-6 lg:hidden"
    >
      <div class="flex flex-col md:flex-row">
        <div class="mb-1 mr-4 flex flex-col">
          <Headline tag="p" look="h1" weight="medium">{{
            $t('hero.store_finder.heading')
          }}</Headline>
          <span>{{ $t('hero.store_finder.copy') }}</span>
        </div>
        {{'<HeroStoreFinderSearch class="w-full md:w-auto self-end" /> '}}
      </div>
    </div>
    <ButtonBox
      v-for="(boxBlok, i) in blok.buttonBox"
      :key="i"
      id-prefix="mobile-"
      class="flex w-full md:hidden"
      :blok="boxBlok"
    />
  </Intersect>
</template>

<script setup lang="ts">
import type { SbHero } from '../types/storyblok'
import {
  parseImageRef,
  parseHeadlineRef,
  parseTextRef,
  parseColor,
} from '~/utils/storyblok'

const props = defineProps({
  preloadMedia: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  blok: {
    type: Object as PropType<SbHero>,
    default: () => {},
  },
})
const { marginClasses } = useStoryblokMargins(props.blok)
const { trackButtonClick, trackPromotionView, trackPromotionClick } =
  await useTrackingEvents()

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}

const image = computed(() => parseImageRef(props.blok.image_ref))
const headline = computed(() =>
  parseHeadlineRef(props.blok.headline_ref, {
    tag: HeadlineTag.P,
  }),
)
const link = computed(() => parseLinkRef(props.blok.cta_ref))
const text = computed(() => parseTextRef(props.blok.text_ref))

const logoImageSrc = computed(() => props.blok.logo?.filename)
const logoImageAlt = computed(() => props.blok.logo?.alt ?? '')
const showButtonBox = computed(() => Boolean(props.blok.buttonBox?.length))
const showStoreFinder = computed(
  () => Boolean(props.blok.show_store_finder) && !showButtonBox.value,
)

const contentWidthClasses = computed(() =>
  ['w-full', 'sm:w-1/2', 'lg:w-1/3'].slice(
    0,
    parseFloat(props?.blok?.max_columns ?? '2'),
  ),
)

const accentColor = computed(() =>
  parseColor(props.blok.accent_color, '#ffffff'),
)
const accentLuminance = computed(() => {
  try {
    const rgb = hex2rgb(accentColor.value)
    return relativeLuminanceW3C(...rgb)
  } catch {
    return 1
  }
})

const hasGradient = computed(() => Boolean(props.blok.show_gradient))
const gradientPosition = computed(() => {
  const align = props.blok?.align ?? 'start'
  return { [`gradient-${align}`]: true }
})
const isDark = computed(() => props.blok.theme === 'dark')
const isLight = computed(() => props.blok.theme === 'light')
const isFullHeight = computed(() => props.blok.height === 'full')
const isSmallHeight = computed(() => props.blok.height === 'sm')
const isMediumHeight = computed(() => props.blok.height === 'md')
const isLargeHeight = computed(() => props.blok.height === 'lg')
const isXtraLargeHeight = computed(() => props.blok.height === 'xl')

const buttonType = computed(() =>
  toButtonType(
    link.value?.cta_type,
    isLight.value ? ButtonType.SECONDARY : ButtonType.PRIMARY,
  ),
)

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}
</script>

<style scoped>
.gradient-start::before,
.gradient-center::before,
.gradient-end::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 18rem;
  width: 100%;
  max-width: 56rem;
}

.gradient-light::before {
  background: linear-gradient(
    180deg,
    hsl(0deg 0% 100% / 0),
    hsl(0deg 0% 100% / 0.45)
  );
}

.gradient-dark::before {
  background: linear-gradient(180deg, transparent, rgb(0 0 0 / 0.75));
}

.with-checkmarks ul {
  margin-left: 2rem;
}

@media (width >= 40rem) {
  .gradient-start::before,
  .gradient-center::before,
  .gradient-end::before {
    height: 100%;
    width: 60%;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .gradient-center::before {
    width: 75%;
  }

  .gradient-end::before {
    left: auto;
    right: 0;
    transform: rotate(180deg);
  }

  .gradient-light::before {
    background: linear-gradient(
      270deg,
      hsl(0deg 0% 100% / 0),
      hsl(0deg 0% 100% / 0.45)
    );
  }

  .gradient-dark::before {
    background: linear-gradient(270deg, transparent, rgb(0 0 0 / 0.45));
  }
}
</style>
